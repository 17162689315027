import React, {useEffect, useState} from 'react';
import {get_ajax, get_ajax_sync, post} from 'shared/dist/utils/DataProvider';
import {LoadingCircleComponent} from 'shared/dist/components/generic-building-blocks/CircleLoadingIndicator';
import {LayoutCard} from 'shared/dist/components/generic-building-blocks/LayoutCard';
import {Link, Redirect} from 'react-router-dom';
import {CustomForm} from 'shared/dist/components/custom_forms/CustomForm';
import {RoundButton, RoundRedirectButton} from 'shared/dist/components/generic-building-blocks/RoundButton';
import { ConfirmationDialog, DeleteConfirmationDialog, UpdateResultSuccessDialog } from 'shared/dist/components/generic-building-blocks/UserDialogs';
import {Modal, ModalBody, ModalFooter, ModalHeader, Button} from "reactstrap";
import {MdPreview, MdSave, MdDeleteOutline, MdModeEditOutline, MdOutlineDragHandle, MdOutlineSettingsSuggest, MdPendingActions, MdOutlineVisibilityOff} from 'react-icons/md';
import {BsFillCloudDownloadFill} from 'react-icons/bs';
import { printPage } from 'shared/dist/utils/PdfBuilder';
import { getAccessToken, GetRoles } from 'shared/dist/components/api-authorization/AuthService';

export const DynamicRouteElementPage = (props) => {
    useEffect(() => {
        setSelectedModelId(props.match.params.model_id);
        setSelectedInstanceId(props.match.params.instance_id);
        setSelectedAction(props.match.params.action);
    }, [props.match.params.model_id, props.match.params.instance_id]);

    const [selectedAction, setSelectedAction] = useState(props.match.params.action);
    const [selectedModelId, setSelectedModelId] = useState(props.match.params.model_id);
    const [selectedModelType, setSelectedModelType] = useState(props.match.params.model_type);
    const [selectedInstanceId, setSelectedInstanceId] = useState(props.match.params.instance_id);
    
    const [modelPreviewTitle, setModelPreviewTitle] = useState(undefined);
    const [modelData, setModelData] = useState(undefined);
    const [objectData, setObjectData] = useState(undefined);
    
    const [modelHistoryRecords, setModelHistoryRecords] = useState(undefined);
    const [modelGeneratedDocuments, setModelGeneratedDocuments] = useState(undefined);
    const [selectedPreviewHistoryRecordId, setSelectedPreviewHistoryRecordId] = useState(undefined);
    
    useEffect(()=>{
        get_ajax(`CustomForms/GetModelGenericData?type=${selectedModelType}`, (modelMetadata) => {
            setModelData(modelMetadata);
            
            get_ajax(`CustomForms/GetInstacePreviewString?type=${selectedModelType}&instanceId=${props.match.params.instance_id}`, (preview) => {
                setModelPreviewTitle(preview);
            });
        });

        get_ajax('Dynamic/GetDynamicModelSettings?modelType=' + selectedModelType, (modelSettings) => {
            if(modelSettings["allowViewModelHistory"] === "false")
                setModelHistoryRecords([]);
            else {
                get_ajax(`CustomForms/GetInstanceUpdateRecords?instanceId=${props.match.params.instance_id}`, (historyRecords) => {
                    setModelHistoryRecords(historyRecords);
                });
            }
            
            if(modelSettings["allowViewGeneratedDocs"] === "false")
                setModelGeneratedDocuments([]);
            else {
                get_ajax(`CustomForms/GetInstanceGeneratedDocuments?instanceId=${props.match.params.instance_id}`, (documentRecords) => {
                    setModelGeneratedDocuments(documentRecords);
                });
            }
        });
    }, [selectedModelType, selectedInstanceId]);

    useEffect(()=>{
        get_ajax('Dynamic/GetDynamicModelSettings?modelType=' + selectedModelId, (data) => {
            setActionsList(data['allowedModelActions'] ? data['allowedModelActions'] : []);
        });

    }, [selectedModelType]);
    const [actionsList, setActionsList] = useState(undefined);
    
    useEffect(()=>{
        loadInstanceData();
    }, [selectedInstanceId]);
    
    const loadInstanceData = () => {
        setIsLoading(true);
        get_ajax(`CustomForms/GetCustomObjectInstance?objectId=${selectedInstanceId}&type=${selectedModelType}`, (objectInstance) => {
            setObjectData(JSON.parse(objectInstance.objectData));
            setIsLoading(false);
        });

        get_ajax('Dynamic/GetDynamicModelSettings?modelType=' + selectedModelType, (modelSettings) => {
            if(modelSettings["allowViewModelHistory"] === "false")
                setModelHistoryRecords([]);
            else {
                get_ajax(`CustomForms/GetInstanceUpdateRecords?instanceId=${props.match.params.instance_id}`, (historyRecords) => {
                    setModelHistoryRecords(historyRecords);
                });
            }

            if(modelSettings["allowViewGeneratedDocs"] === "false")
                setModelGeneratedDocuments([]);
            else {
                get_ajax(`CustomForms/GetInstanceGeneratedDocuments?instanceId=${props.match.params.instance_id}`, (documentRecords) => {
                    setModelGeneratedDocuments(documentRecords);
                });
            }
        });
    }
    
    const [isLoading, setIsLoading] = useState(false);
    
    const [isSaveDialogOpen, setIsSaveDialogOpen] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [isSaveConfirmationDialogOpen, setIsSaveConfirmationDialogOpen] = useState(false);
    const [redirectUrl, setRedirectUrl] = useState(undefined);
    
    const [editFormActionTitle, setEditFormActionTitle] = useState('');
    const [editFormActionDialogEnabled, setEditFormActionEnabled] = useState(false);
    const [editFormActionSelectedActionId, setEditFormActionSelectedActionId] = useState(undefined);
    const [editFormActionPayload, setEditFormActionPayload] = useState({});
    const [editFormActionSelectedFormId, setEditFormActionSelectedFormId] = useState(undefined);

    const [previewHTML, setPreviewHTML] = useState(undefined);
    const [previewHTMLDocId, setPreviewHTMLDocId] = useState(undefined);
    
    const downloadFile = (documentId, docName) => {
        let url = `CustomForms/GetInstanceGeneratedDocument?documentId=${documentId}`;
        var blob;
        var xmlHTTP = new XMLHttpRequest();
        xmlHTTP.open('GET', url, true);
        xmlHTTP.responseType = 'arraybuffer';
        xmlHTTP.onload = function(e) {
            blob = new Blob([this.response]);
        };
        xmlHTTP.onprogress = function(pr) {
            // TODO Maybe add some progress bar to the user later on
            //pr.loaded - current state
            //pr.total  - max
        };
        xmlHTTP.onloadend = function(e){
            var fileName = `${docName}.pdf`;
            var tempEl = document.createElement("a");
            tempEl.style.display = "none";
            document.body.appendChild(tempEl);
            url = window.URL.createObjectURL(blob);
            tempEl.href = url;
            tempEl.download = fileName;
            tempEl.click();
            window.URL.revokeObjectURL(url);
        }
        const token = getAccessToken();
        xmlHTTP.setRequestHeader('Authorization', token);
        xmlHTTP.send();
    }
    
    if(modelData === undefined || objectData === undefined || modelPreviewTitle === undefined)
    {
        return (
            <LoadingCircleComponent/>
        );
    }
    return (
        <>
            <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                <div className={'row'}>
                    <div className={'col-md-8'}>
                        <h4><Link to={`/dynamic-route/${selectedModelId}`}>{modelData.displayName}</Link> &gt; {modelPreviewTitle}</h4>
                    </div>
                    <div className={'col-md-4'}>
                        {selectedAction === 'edit' && (
                            <div className={'row'}>
                                <div className={'col-md-6'}>
                                    <RoundButton
                                        className="custom-btn-primary"
                                        text={'Salveaza'}
                                        onClickHandler={()=>{ setIsSaveDialogOpen(true); }}
                                    />
                                </div>
                                <div className={'col-md-6'}>
                                    <RoundButton
                                        className="btn-outline-danger"
                                        text={'Sterge'}
                                        onClickHandler={()=>{ setIsDeleteDialogOpen(true); }}
                                    />
                                </div>
                            </div>  
                        )}
                    </div>
                </div>
            </LayoutCard>
            {actionsList !== undefined && (
                <div className={'row'}>
                    <div className={actionsList.length ? 'col-md-8' : 'col-md-12'}>
                        <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                            {isLoading && (<LoadingCircleComponent/>)}
                            {!isLoading && (
                                <CustomForm
                                    formId={props.match.params.form_id}
                                    defaultValues={objectData}
                                    disableAll={selectedAction !== 'edit'}
                                    updateValues={(values) => {
                                        if(selectedAction !== 'edit') return ;
                                        setObjectData(values);
                                    }}
                                />  
                            )}
                        </LayoutCard>
                        {modelGeneratedDocuments === undefined && (<LoadingCircleComponent/>)}
                        {modelGeneratedDocuments !== undefined && (
                            <>
                                {modelGeneratedDocuments.length > 0 && (
                                    <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                                        <h6><b>Documente generate</b></h6>
                                        <br/>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Nume document</th>
                                                    <th scope="col">Generat la data de</th>
                                                    <th scope="col"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {modelGeneratedDocuments.map((doc, i) =>
                                                    <tr key={doc.id}>
                                                        <th scope="row">{i+1}</th>
                                                        <td>{doc.pdfName}</td>
                                                        <td>{doc.generatedAt}</td>
                                                        {!doc.pdfGenerated && (
                                                            <td>
                                                                <RoundButton
                                                                    className={"custom-btn-primary"}
                                                                    text={<MdPreview/>}
                                                                    onClickHandler={()=>{
                                                                        get_ajax(`CustomForms/GetInstanceGeneratedDocumentPreview?documentId=${doc.id}`, (preview) => {
                                                                            setPreviewHTML(preview);
                                                                            setPreviewHTMLDocId(doc.id);
                                                                        });
                                                                    }}
                                                                />
                                                            </td>
                                                        )}
                                                        {doc.pdfGenerated && (
                                                            <td>
                                                                <RoundButton
                                                                    className={"custom-btn-primary"}
                                                                    text={<BsFillCloudDownloadFill/>}
                                                                    onClickHandler={()=>{
                                                                        downloadFile(doc.id, doc.pdfName);
                                                                    }}
                                                                />
                                                            </td>  
                                                        )}
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </LayoutCard>
                                )}
                            </>
                        )}
                    </div>
                    {actionsList.length > 0 && (
                        <div className={'col-md-4'}>
                            <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                                <h6><b>Actiuni disponibile</b></h6>
                                <br/>
                                {actionsList.map((action, i) =>
                                    <RoundButton
                                        key={i}
                                        className="custom-btn-primary"
                                        text={action.actionName}
                                        onClickHandler={()=>{
                                            const actionId = action.id;
                                            const actionName = action.actionName;
                                            get_ajax(`CustomForms/GetCustomModelAction?formId=${props.match.params.form_id}&actionId=${actionId}`, (configJSON) => {
                                               const stepsList = JSON.parse(configJSON);
                                               for(let i  = 0; i < stepsList.length; i++)
                                               {
                                                   const step = stepsList[i];
                                                   if(step.type === 2) {
                                                      if(step.modelInstanceSelector.referenceType === 0 ) {
                                                          setEditFormActionTitle(actionName);
                                                          setEditFormActionSelectedActionId(actionId);
                                                          setEditFormActionPayload(objectData);
                                                          setEditFormActionSelectedFormId(
                                                                step.actionPayload.selectedForm
                                                          );
                                                          setEditFormActionEnabled(true);
                                                          return;
                                                      } else {
                                                          alert("THIS OPTION IS NOT AVAILABLE YET");
                                                      }
                                                  } 
                                               }

                                                // Check if action has frontend steps.
                                                // TODO Maybe this should be revised, and trigger each step... or even better. UI triggers through SIGNALR
                                                // RIGHT NOW IF YOU HAVE UI ACTIONS THE OTHER ONES ARE DISABLED :(
                                                post('CustomForms/ExecuteCustomModelAction', {
                                                    'formId': props.match.params.form_id,
                                                    'objectId': selectedInstanceId,
                                                    'actionId': actionId
                                                }, (response) => {
                                                    if(response !== true) {
                                                        alert("Eroare la executarea actiunii");
                                                    } else {
                                                        loadInstanceData();
                                                    }
                                                });
                                            });
                                        }}
                                    />
                                )}
                            </LayoutCard>
                            {modelHistoryRecords === undefined && (<LoadingCircleComponent/>)}
                            {(modelHistoryRecords !== undefined) && (
                                <>
                                    {modelHistoryRecords.length > 0 && (
                                        <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                                            <h6><b>Istoric modificari</b></h6>
                                            <br/>
                                            <ul className={'list-group'}>
                                                {modelHistoryRecords.map((record, i) =>
                                                    <li key={i} 
                                                        className={'list-group-item list-group-item-action'} 
                                                        style={{cursor: 'pointer'}}
                                                        onClick={()=>{
                                                            setSelectedPreviewHistoryRecordId(record.id);
                                                        }}
                                                    >
                                                        {record.dataModificare}
                                                    </li>
                                                )}
                                            </ul>
                                        </LayoutCard>     
                                    )}
                                </>
                            )}
                        </div>
                    )}
                </div>
            )}
            
            <hr/>
            {/*{JSON.stringify(objectData)}*/}

            <ConfirmationDialog
                isOpen={isSaveDialogOpen}
                toggle={() =>{setIsSaveDialogOpen(false)}}
                onConfirm={()=>{
                    post('CustomForms/UpdateObjectInstance', {
                        'formId': props.match.params.form_id, 
                        'objectId': selectedInstanceId, 
                        'jsonPayload': JSON.stringify(objectData)
                    }, (response) => {
                        if(response === false) {
                            alert("Eroare la salvare");
                        } else {
                            setIsSaveConfirmationDialogOpen(true);
                        }
                    });
                }}
                label='Salveaza'
                text='Confirmati salvarea? Actiunea va fi inregistrata in istoric.'
            />
            <DeleteConfirmationDialog
                isOpen={isDeleteDialogOpen}
                toggle={()=>{setIsDeleteDialogOpen(!isDeleteDialogOpen)}}
                onConfirm={()=>{
                    post('CustomForms/DeleteObjectInstance', {
                        'formId': props.match.params.form_id,
                        'objectId': selectedInstanceId,
                    }, (response) => {
                        if(response === false) {
                            alert("Eroare la stergere");
                        } else {
                            setRedirectUrl(`/dynamic-route/${selectedModelId}`);
                        }
                    });
                }}
            />
            <UpdateResultSuccessDialog
                isOpen={isSaveConfirmationDialogOpen}
                toggle={() => {setIsSaveConfirmationDialogOpen(false)}}
                onConfirm={() => { loadInstanceData(); }}
            />
            {redirectUrl !== undefined && (
                <Redirect to={redirectUrl}/>
            )}
            {editFormActionSelectedFormId !== undefined && (
                <Modal isOpen={editFormActionDialogEnabled} toggle={()=>{setEditFormActionEnabled(false);}} size={'xl'}>
                    <ModalHeader>{editFormActionTitle}</ModalHeader>
                    <ModalBody>
                        <CustomForm
                            formId={editFormActionSelectedFormId}
                            defaultValues={objectData}
                            updateValues={(values) => { setEditFormActionPayload(values); }}
                        />
                    </ModalBody>
                    <ModalFooter style={{display: 'initial'}}>
                        <div className={'row'}>
                            <div className={'col-md-2'}>
                                <RoundButton
                                    className="btn-secondary"
                                    text={'Anuleaza'}
                                    onClickHandler={()=>{setEditFormActionEnabled(false); }}
                                />
                            </div>
                            <div className={'col-md-6'}></div>
                            <div className={'col-md-4'}>
                                <RoundButton
                                    className="custom-btn-primary"
                                    text={`Salveaza`}
                                    onClickHandler={()=>{
                                        post("CustomForms/UpdateObjectViaAction", {
                                            'instanceId': props.match.params.instance_id,
                                            'formId': editFormActionSelectedFormId,
                                            'actionId': editFormActionSelectedActionId,
                                            'jsonPayload': JSON.stringify(editFormActionPayload)
                                        }, (response) => {
                                            setEditFormActionSelectedFormId(undefined);
                                            loadInstanceData();
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </ModalFooter>
                </Modal>
            )}
            <HistoryRecordPreviewDialog
                previewRecordId={selectedPreviewHistoryRecordId}
                close={()=>{setSelectedPreviewHistoryRecordId(undefined);}}
                formId={props.match.params.form_id}
            />
            <Modal isOpen={previewHTML!==undefined} toggle={()=>{setPreviewHTML(undefined)}} size={'xl'}>
                <ModalHeader>
                    Previzualizare
                </ModalHeader>
                <ModalBody>
                    <div className={'row'}>
                        <div className='col-md-8'></div>
                        <div className='col-md-4'>
                            <RoundButton
                                className={"custom-btn-primary"}
                                text="Print / Save PDF"
                                onClickHandler={()=>{
                                    printPage(`/PdfRendering/GetInstanceGeneratedDocumentPreview?documentId=${previewHTMLDocId}`);
                                }}
                            />
                        </div>
                    </div>
                    <div
                        style={{border: '1px solid #2f3542', display: 'block', margin: 'auto', marginTop: '10px'}}
                        dangerouslySetInnerHTML={{__html: previewHTML}}
                    ></div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" className="no-outline" onClick={()=>{setPreviewHTML(undefined)}}>Inchide</Button>
                </ModalFooter>
            </Modal>
        </>  
    );  
} 

const HistoryRecordPreviewDialog = ({previewRecordId, close, formId}) => {
    const [modifiedAt, setModifiedAt] = useState('');
    const [modifiedBy, setModifiedBy] = useState('');
    
    const [previousData, setPreviousData] = useState({});
    const [currentData, setCurrentData] = useState({});
    
    const [isLoading, setIsLoading] = useState(true);
    
    useEffect(()=>{
        if(previewRecordId === undefined) return;
        setIsLoading(true);
        get_ajax(`CustomForms/GetHistoryRecordData?recordId=${previewRecordId}`, (data) => {
            setModifiedAt(data.modifiedAt);
            setModifiedBy(data.modifiedBy);
            setPreviousData(JSON.parse(data.previousData));
            setCurrentData(JSON.parse(data.currentData));
            
            setIsLoading(false);
        });
    }, [previewRecordId]);
    
    return (
        <Modal isOpen={previewRecordId!==undefined} toggle={close} size={'xl'}>
            <ModalHeader>Istoric modificare</ModalHeader>
            <ModalBody>
                {isLoading && (<LoadingCircleComponent/>)}
                {!isLoading && (
                    <>
                        <div className={'row'}>
                            <div className={'col-md-6'}>
                                <h6><b>{modifiedBy}</b></h6>
                            </div>
                            <div className={'col-md-6'}>
                                <h6><b>{modifiedAt}</b></h6>
                            </div>
                        </div>
                        <hr/>
                        <div className={'row'}>
                            <div className={'col-md-6'}>
                                <p>Original</p>
                                <CustomForm
                                    formId={formId}
                                    defaultValues={previousData}
                                    disableAll={true}
                                    updateValues={(values) => {}}
                                />
                            </div>
                            <div className={'col-md-6'}>
                                <p>Modificat</p>
                                <CustomForm
                                    formId={formId}
                                    defaultValues={currentData}
                                    disableAll={true}
                                    updateValues={(values) => {}}
                                />
                            </div>
                        </div>
                    </>
                )}
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" className="no-outline" onClick={close}>Inchide</Button>
            </ModalFooter>
        </Modal>
    );
}