import React, { useEffect, useState } from 'react';
import { LayoutCard } from 'shared/dist/components/generic-building-blocks/LayoutCard';
import AOS from 'aos';
import "aos/dist/aos.css";
import {get, get_ajax, post} from 'shared/dist/utils/DataProvider';
import {LoadingCircleComponent} from 'shared/dist/components/generic-building-blocks/CircleLoadingIndicator';
import {IoWarningOutline, IoCheckmark, IoHourglassOutline} from 'react-icons/io5';
import {Link, Redirect} from 'react-router-dom';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { displayToastNotification, TOAST_NOTIFICATION, useGlobalSync } from  'shared/dist/utils/NotificationsContext';
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  
export const chart_options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
            onClick: null
        },
    },    
    scales: {
        y: {
            ticks: {
                precision: 0
            }
        },
    },
};

export const Home = () => {
    useEffect(()=>{
        AOS.init();
    }, []);

    // const role = window.localStorage.getItem('__current_role__');
    // if(role === "Medic ContractsManagement")
    //     return (<MedicHomePage/>);
    // if(role === "Dosar medical")
    //     return (<MedicalControlSSMHomePage/>);
    return (
        <>
            <br/>
            <div className="row">
                <div className="col-md-6">
                    <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                        <div>
                            <h5>Bun venit!</h5>
                            <label>Va uram o zi frumoasa!</label>
                        </div>
                    </LayoutCard>
                </div>
            </div>
        </>
    )
}