import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { Home } from './pages/Home';

import './App.scss';
import { getAccessToken, GetRoles } from 'shared/dist/components/api-authorization/AuthService';
import {ClientPersonalizedLayout} from 'shared/dist/components/ClientPersonalizedLayout';
import {PaddingBox} from 'shared/dist/components/generic-building-blocks/PaddingBox';
import { BsPeopleFill, BsTable } from "react-icons/bs";
import {GoSettings} from 'react-icons/go';
import { LoadingCircleComponent } from 'shared/dist/components/generic-building-blocks/CircleLoadingIndicator';
import { CenterScreenContainer } from 'shared/dist/components/generic-building-blocks/CenterScreenContainer';
import {FaFileMedicalAlt, FaLayerGroup} from 'react-icons/fa';
import {FaCommentMedical} from 'react-icons/fa';
import { BsListCheck } from 'react-icons/bs';
import { RiArchiveFill } from 'react-icons/ri';
import {MdOutlineModelTraining} from 'react-icons/md'; 
import {IoHourglassOutline} from 'react-icons/io5';
import {get_ajax_sync} from 'shared/dist/utils/DataProvider';
import {DynamicRoutePage} from "./pages/DynamicRoute/DynamicRoute";
import {DynamicRouteElementPage} from "./pages/DynamicRoute/DynamicRouteElement";

export default class App extends Component {
    static displayName = App.name;    

    constructor(props)
    {
        super(props);        
        this.state = {
          current_role: undefined,     
          isLoadingNewUI: false,   
          routes: []  
        };

        let allowed_roles = [];
        getAccessToken();
        GetRoles((roles)=> {                    
          allowed_roles = roles.filter((e)=>{
            return (
              e.name == "GlobalAdmin" || e.name == "Administrator"
            );
          });              
          let c_role = {name: window.localStorage.getItem("__current_role__")};
          if(
              window.localStorage.getItem("__current_role__") != null &&
              window.localStorage.getItem("__current_role__") != "null" &&
              window.localStorage.getItem("__current_role__") != ""
          )
          {
            if(c_role === '' || c_role === null || c_role === undefined)
              c_role = allowed_roles[0];
          } 
          else{
            c_role = allowed_roles[0];
          }  
          this.setState({allowed_roles: allowed_roles, current_role: c_role});
        });  
        setTimeout(() => {
          if(this.state.current_role === undefined || this.state.isLoadingNewUI)
          {
            window.location.reload();
          }
        }, 5 * 1000)
    }

    returnAllowedRoutes = () => {                  
        const current_role = this.state.current_role.name;   
        if(current_role === undefined) return ([]);
        
        window.localStorage.setItem("__current_role__", current_role);
        const sideMenuOptions = get_ajax_sync("Dynamic/GetSideMenuOptions");
        let allowedRoutes = [];
        sideMenuOptions.forEach(option => {
           allowedRoutes.push({
               "route": `/dynamic-route/${option.id}`,
               "name": option.name,
               "icon": option.icon ? <span dangerouslySetInnerHTML={{__html: option.icon}}></span> : <MdOutlineModelTraining size={28}/>
           }); 
        });
        return allowedRoutes;
    }

    render() {  
        return (
          <>
          {(this.state.current_role === undefined || this.state.isLoadingNewUI && (
            <CenterScreenContainer>
              <LoadingCircleComponent/>
            </CenterScreenContainer>
          ))}
          {(!(this.state.current_role === undefined || this.state.isLoadingNewUI) && (
            <ClientPersonalizedLayout
              app={'cim'}                     
              routes={this.returnAllowedRoutes()}   
              roleChanged={(new_role) => {
                this.setState({current_role: {name: new_role, isLoadingNewUI: true }});
                window.localStorage.setItem("__current_role__", new_role);
                window.location.href='/';
              }}          
            >
              <PaddingBox
                top='0'
                right='0'
                bottom='0'
                left='15px'
              >                
                <Route exact path='/' component={Home} />
                <Route exact path='/dynamic-route/:model_id' component={DynamicRoutePage} />
                <Route exact path='/dynamic-route/:model_id/:action/:model_type/:instance_id/:form_id' component={DynamicRouteElementPage} />
              </PaddingBox>              
            </ClientPersonalizedLayout>
          ))}
          </>
        );
  }
}