import React, {useEffect, useState} from 'react';
import {LayoutCard} from 'shared/dist/components/generic-building-blocks/LayoutCard';
import {SearchBox} from 'shared/dist/components/generic-building-blocks/SearchBox';
import {LoadingCircleComponent} from 'shared/dist/components/generic-building-blocks/CircleLoadingIndicator';
import {get_ajax, get_ajax_sync, post} from 'shared/dist/utils/DataProvider';
import {CustomForm} from 'shared/dist/components/custom_forms/CustomForm';
import {RoundButton, RoundRedirectButton} from 'shared/dist/components/generic-building-blocks/RoundButton';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button, UncontrolledTooltip} from "reactstrap";

export const DynamicRoutePage = (props) => {
    useEffect(() => {
        setSelectedModelId(props.match.params.model_id);
    }, [props.match.params.model_id]);
    
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingData, setIsLoadingData] = useState(true);
    
    const [selectedModelId, setSelectedModelId] = useState(props.match.params.model_id);
    const [selectedModelName, setSelectedModelName] = useState(undefined);
    const [selectedModelType, setSelectedModelType] = useState(undefined);
    useEffect(()=>{
        setIsLoading(true);
        get_ajax('Dynamic/GetDynamicModelSettings?modelType=' + selectedModelId, (data) => {
            const sideMenuOptions = get_ajax_sync("Dynamic/GetSideMenuOptions");
            setSelectedModelName(sideMenuOptions.filter(x => x.id === selectedModelId)[0].name);
            setIsLoading(false);
            setMetaData(data);
            setSelectedModelType(sideMenuOptions.filter(x => x.id === selectedModelId)[0].type);
        });
    }, [selectedModelId]);
    
    useEffect(()=>{
        if(selectedModelType === undefined) return;
        loadData();
    }, [selectedModelType]);
    const [metaData, setMetaData] = useState(undefined);
    
    const [isAddNewElementModalVisible, setIsAddNewModalVisible] = useState(false);
    const [newElementModelPayloadData, setNewElemenetPayloadData] = useState({});
    const [searchBoxValue,setSearchBoxValue] = useState('');
    
    const [pageData, setPageData] = useState([]);
    
    const loadData = () => {
        get_ajax("CustomForms/GetObjects?type=" + selectedModelType, (objects) => {
            const _objects = [];
            objects.forEach(obj => {
                const _obj = obj;
                _obj.objectData = JSON.parse(_obj.objectData);
                _objects.push(_obj);
            })
            setPageData(_objects);
            setIsLoadingData(false);
        });
    }
    const addCustomElementToDatabase = () => {
        post("CustomForms/CreateObjectInstance", {
            'formId': metaData["newElementFormOption"], 
            'jsonPayload': JSON.stringify(newElementModelPayloadData)
        }, (response) => {
            if(response === 'error_inserting')
            {
                alert("Eroare la adaugarea elementului..."); return;
            }
            if(metaData['listViewType'] === 'disabled')
            {
                alert("Adaugat cu succes!");
                window.location.reload();
            }
            else {
                setIsAddNewModalVisible(false);
                loadData();
            }
        })
    }
    
    
    
    if(isLoading || metaData === undefined || selectedModelName === undefined) {
        return ( <LoadingCircleComponent/> );
    }
    else if(metaData['listViewType'] === 'disabled')
    {
        if(metaData["newElementFormOption"] === 'disabled')
        {
            return (<>No action allowed. Please configure</>);
        }
        else 
        {
            return (
                <div className={'container'}>
                    <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                        <h4>{selectedModelName}</h4>
                        <br/>
                        <CustomForm
                            formId={metaData["newElementFormOption"]}
                            // TODO Have these only for employee... they should not be hard coded like this, but set somehow
                            defaultValues={{"@tara":"Romania", "@judet":"Bacau"}}
                            updateValues={(values) => { setNewElemenetPayloadData(values); }}
                        />
                        <hr/>
                        <div className={'row'}>
                            <div className={'col-md-8'}></div>
                            <div className={'col-md-4'}>
                                <RoundButton
                                    className="custom-btn-primary"
                                    text={`Adauga ${selectedModelName}`}
                                    onClickHandler={()=>{addCustomElementToDatabase();}}
                                />
                            </div>
                        </div>
                    </LayoutCard>
                </div>
            ); 
        }
    }
    return (
        <>
            <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                <div className="row">
                    <div className="col-md-6">
                        <h4>{selectedModelName}</h4>
                        <br/>
                        <SearchBox
                            value={searchBoxValue}
                            placeholder="Cauta"
                            onChange={(e) =>{ setSearchBoxValue(e.target.value); }}
                        />
                    </div>
                    <div className={'col-md-6'}>
                        <div className={'row'}>
                            <div className={'col-md-6'}></div>
                            <div className={'col-md-6'}>
                                {(metaData["newElementFormOption"] && metaData["newElementFormOption"] !== 'disabled') && (
                                    <RoundButton
                                        className="custom-btn-primary"
                                        text="Adauga"
                                        onClickHandler={()=>{setIsAddNewModalVisible(true);}}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </LayoutCard>
            {isLoadingData && (<LoadingCircleComponent/>)}
            {!isLoading && (
                <>
                    {metaData['listViewType'] === 'tableView' && (
                        <>
                            <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            {metaData['listViewTableSelectedColumns'].map((column, index) =>
                                                <th key={index} scope="col" style={{borderTop: 'none'}}>{column.name}</th>
                                            )}
                                            {(metaData["editElementFormOption"] && metaData["editElementFormOption"] !== 'disabled') && (<th style={{borderTop: 'none'}}></th>)}
                                            {(metaData["viewElementFormOption"] && metaData["viewElementFormOption"] !== 'disabled') && (<th style={{borderTop: 'none'}}></th>)}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {pageData.map((element, index)=>
                                            <tr key={index}>
                                                {metaData['listViewTableSelectedColumns'].map((column, j) =>
                                                    <td key={j}>
                                                        {typeof (element.objectData[column.id]) === 'object' && 'nume' in element.objectData[column.id] && (
                                                            element.objectData[column.id]['nume']
                                                        )}
                                                        {typeof (element.objectData[column.id]) !== 'object' && (
                                                            element.objectData[column.id]
                                                        )}
                                                    </td>
                                                )}
                                                {(metaData["editElementFormOption"] !== 'disabled' && metaData["editElementFormOption"] !== undefined) && (
                                                    <td>
                                                        <RoundRedirectButton
                                                            className="btn-secondary"
                                                            text={'Editeaza'}
                                                            redirectUrl={`/dynamic-route/${selectedModelId}/edit/${selectedModelType}/${element.id}/${metaData["editElementFormOption"]}`}
                                                        />
                                                    </td>
                                                )}
                                                {(metaData["viewElementFormOption"] !== 'disabled' && metaData["viewElementFormOption"] !== undefined) && (
                                                    <td>
                                                        <RoundRedirectButton
                                                            className="btn-secondary"
                                                            text={'Vizualizeaza'}
                                                            redirectUrl={`/dynamic-route/${selectedModelId}/view/${selectedModelType}/${element.id}/${metaData["viewElementFormOption"]}`}
                                                        />
                                                    </td>
                                                )}
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </LayoutCard>
                        </>
                    )}
                    {metaData['listViewType'] === 'gridView' && (
                        <>
                            <h1>Grid</h1>
                        </>
                    )}
                </>
            )}
            {JSON.stringify(metaData)}


            {metaData["newElementFormOption"] !== 'disabled' && (
                <Modal isOpen={isAddNewElementModalVisible} toggle={()=>{setIsAddNewModalVisible(false);}} size={'xl'}>
                    <ModalHeader>{selectedModelName}</ModalHeader>
                    <ModalBody>
                        <CustomForm
                            formId={metaData["newElementFormOption"]}
                            // TODO Have these only for employee... they should not be hard coded like this, but set somehow
                            defaultValues={{"@tara":"Romania", "@judet":"Bacau"}}
                            updateValues={(values) => { setNewElemenetPayloadData(values); }}
                        />
                    </ModalBody>
                    <ModalFooter style={{display: 'initial'}}>
                        <div className={'row'}>
                            <div className={'col-md-2'}>
                                <RoundButton
                                    className="btn-secondary"
                                    text={'Anuleaza'}
                                    onClickHandler={()=>{setIsAddNewModalVisible(false); }}
                                />
                            </div>
                            <div className={'col-md-6'}></div>
                            <div className={'col-md-4'}>
                                <RoundButton
                                    className="custom-btn-primary"
                                    text={`Adauga ${selectedModelName}`}
                                    onClickHandler={()=>{addCustomElementToDatabase();}}
                                />
                            </div>
                        </div>
                    </ModalFooter>
                </Modal>
            )}
        </>
    );
}